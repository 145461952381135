<template>
    <div>
      <a-row class="header-search" style="margin-top: 8px;">
        <a-input class="search" placeholder="    Pesquisar" size="large" v-model="searchTerm" style="height: 50px !important;"> 
          <template slot="prefix">
            <svg style="margin-left: 4px;" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" height="1.2em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>
          </template></a-input>
        <div class="filter"></div>
      </a-row>
  
      <br>
  
      <h6 style="margin-top: 10px;">Adicionados recentemente</h6>
  
      <WidgetOffers></WidgetOffers>
  
      <h6 style="margin-top: -40px;">Todos os conteúdos adquiridos</h6>
  
      <WidgetOffers></WidgetOffers>
  
    </div>
  </template>
  
  <script>
  import WidgetCarrossel from "../../../components/Widgets/WidgetCarrossel";
  import WidgetOffers from "../../../components/Widgets/WidgetOffers.vue";
  
  export default {
    components: {
      // Member components
      WidgetCarrossel,
      WidgetOffers,
    },
    data() {
      return {
        offers: [],
        searchTerm: "",
        isLoadindContents: true,
      };
    },
    computed: {
      filteredOffers() {
        return this.offers.filter((offer) =>
          offer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      },
    },
    methods: {
      /* OBTER AS OFERTAS */
      getOffers() {
        this.$api.dataService
          .getData("member/purchasable")
          .then((res) => {
            if (res.events || res.products || res.courses) {
              this.offers = [
                ...this.mapOffers(res.events, "Event"),
                ...this.mapOffers(res.products, "Product"),
                ...this.mapOffers(res.courses, "Course"),
              ];
            }
            this.isLoadindContents = false;
          })
          .catch((error) => {
            console.error("Erro ao buscar ofertas:", error);
          });
      },
  
      mapOffers(offers, type) {
        if (!offers) return [];
        return offers.map((offer) => ({
          id: offer.id,
          name: offer.name || offer.title, // Para cursos, usa-se 'title'
          description: offer.description,
          img: offer.banner ? offer.banner['1x1'] : "https://lp-js-libs.s3.sa-east-1.amazonaws.com/assets/Plataforma/sem-imagem.jpg",
          type: type,
        }));
      },
  
      /* DEFINIR UM TAMANHO DE 70 CARACTERES PARA A DESCRIÇÃO DA OFERTA */
      truncateDescription(description, maxLength) {
        if (description.length <= maxLength) {
          return description;
        } else {
          return description.slice(0, maxLength) + "...";
        }
      },
    },
    mounted() {
      this.getOffers();
    },
  };
  </script>
  
  <style lang="scss">
  
  .header-search {
    margin-bottom: 1rem;
  }
  
  .ant-input {
    height: 50px;
    border-radius: 65px;
    border: 0;
  }
  
  .offers-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  .offersCards {
    width: 100%;
    height: 100%;
    /* Altura fixa para todos os cards */
    background: #141921;
    border-radius: 12px;
    box-shadow: -1px 1px 10px -3px rgba(0, 0, 0, 0.137);
    cursor: pointer;
    overflow: hidden;
  
    .img {
      overflow: hidden;
      height: 60%;
      /* Altura máxima da imagem em relação à altura do card */
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .info {
      padding: 1rem 1rem 1rem 1rem;
  
      .ant-card-meta-title {
        font-weight: 700;
      }
  
      .ant-card-meta-title,
      .ant-card-meta-description {
        color: #fff;
      }
    }
  
    .skeleton-loading {
      width: 100%;
      height: 150px;
      /* Ajuste a altura conforme necessário */
      background: linear-gradient(90deg, #0e1117 25%, #10161d 50%, #1a232e 75%);
      background-size: 200% 100%;
      animation: loading 3.5s infinite;
      border-radius: 10px;
    }
  
    @keyframes loading {
      0% {
        background-position: 200% 0;
      }
  
      100% {
        background-position: -200% 0;
      }
    }
  }
  </style>
  
  